html {
  background-color: #301710;
  //@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none;
  /* user-select -- это нестандартное свойство */

  -moz-user-select: none;
  /* поэтому нужны префиксы */

  -ms-user-select: none;
  
}

#root {
  background-color: #301710;
  
}


.container {

  @media screen and (max-width: 575px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  @media screen and (max-width: 400px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  
}

.slideInUpTiny {
  animation-name: slideInUpTiny;
  background-color: #301710;
  
}

.user-list {
  height: 120px;
  padding: 20px 15px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #3e2115 !important;
  text-align: left;
  
  @media screen and (max-width: 575px) {
    height: 100px;
  }

  & .user-avatar {
    margin-left: 40px;
    margin-right: 40px;
    height: 80px !important;
    width: 80px !important;
    
    @media screen and (max-width: 575px) {
      margin-left: 5px;
      margin-right: 5px;
      height: 60px !important;
      width: 60px !important;
      line-height: 80px;
    }
  }
  
}

#cup-graph {
padding: 0 !important;
margin-bottom: 20px;
width: 220px;
height: 290px;
display: flex;
flex-direction: column;
justify-content: flex-end;

@media screen and (max-width: 575px) {
  width: 151px;
  height: 199px;
}
  

}

#cup-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
    
}

#ingr-title {

  @media screen and (max-width: 374px) {
    width: 100px;
    padding: 10px 5px !important;
    
  }
}

#ingr-value {

  @media screen and (max-width: 374px) {
    width: 100px;
    padding: 10px 5px !important;
    
  }

}

.card {
  background-color: #3e2115 !important;
  border-radius: 1rem !important;
  
}

.text {
  margin-top: 10px;
  margin-left: 15px;
  @media screen and (max-width: 575px) {
    margin-top: 5px;
  }
}

#item-title {
  font-family: 'Playfair Display', serif;
  font-size: x-large;
  @media screen and (max-width: 575px) {
    font-size: large !important;
  }

}

h5 {
  color: whitesmoke;
  @media screen and (max-width: 575px) {
    font-size: small !important;
  }
}

#slider-title, .rc-slider-mark-text-active {
  color: #be7f65 !important;
}


#back, h6, #volume {
  color: #be7f65;
  @media screen and (max-width: 575px) {
    font-size: small !important;
  }
}

a:hover { 
  text-decoration: none !important; 
}

.text-area {
  min-width: 90px;
}

#cups-img {
   width: 80%;
  min-width: 60px;
  @media screen and (max-width: 650px) {
    width: 60%;
  }
  @media screen and (max-width: 575px) {
    width: 50%;
  }
  @media screen and (max-width: 400px) {
    width: 40%;
  }
}

button {
  
  height: 30px;
  border-radius:15px !important;
  background-color: #612f20;
  border: 0;
  color: whitesmoke;
}

#slider-wrap {
  //width: 80%;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 0;
}

div {
  color: whitesmoke;
  @media screen and (max-width: 575px) {
    font-size: small !important;
  }
}

#loadingMessage {
  visibility: hidden;
  position: relative;
  width: 320px;
  height: 0px;
  background-color: black;
  margin: 0 auto;
}

canvas {
  position: relative;
  visibility: hidden;
}

#output, #cancel-btn {
  visibility: hidden;
}

.ingridients {
  width: inherit;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media screen and (max-width: 575px) {
    width: 151px;
    height: 200px;
  }
    
}

div#coffee {
  background-color: #603020;
}

div#water {
  background-color: #69341f;
  
}

div#milk {
  background-color:#ffede0;
  color: #5c311f !important;
}

div#powder {
  background-color: #803226;
}

div#foam {
  background-color:  #f8dfc0;
  color: #5c311f !important;
}

div#waterP {
  background-color: #89c3de;
  color: #5c311f !important;
}

div#volume {
  font-size: larger;
  font-weight: 300;
}

.slider {
  display: flex;
  margin-bottom: 5px;
  padding-right: 10px;
}

.slider-name {
  width: 70px;
  text-align: start;
  font-size: small;
}

.rc-slider {
  width: 75%;
  
}

#send {
  width: 270px;
}

.rc-slider-mark-text {
  top: -5px;
}